import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'tl-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent {
  @Input() datepickerLabel = '';
  @Input() defaultDate?: string;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  currentDate: Date = new Date();

  protected readonly Date = Date;

  constructor() {

  }
  onDateChange(date: MatDatepickerInputEvent<any, any>): void {
    this.dateSelected.emit(date.value);
  }
}
