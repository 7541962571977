import { Component } from '@angular/core';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ActivePageHelperService } from 'src/app/services/helpers/active-page-helper.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    public activePageHelper: ActivePageHelperService
  ) {}
}
