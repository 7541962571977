import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginRoutingModule } from './pages/auth-pages/login/login-routing.module';
import { PAGES_AND_PATHS } from './constants/pages-and-paths';
import { ForgotPasswordRoutingModule } from './pages/auth-pages/forgot-password/forgot-password-routing.module';
import { CreateCompanyAccountRoutingModule } from './pages/auth-pages/create-company-account/create-company-account-routing.module';
import { CreateAccountRoutingModule } from './pages/auth-pages/create-account/create-account-routing.module';
import { AddAccountDetailsRoutingModule } from './pages/auth-pages/add-account-details/add-account-details-routing.module';
import { VerifyAccountRoutingModule } from './pages/auth-pages/verify-account/verify-account-routing.module';
import { CheckYourEmailRoutingModule } from './pages/auth-pages/check-your-email/check-your-email-routing.module';
import { SelectLanguageRoutingModule } from './pages/shared-pages/select-language/select-language-routing.module';
import { SelectListersRoutingModule } from './pages/shared-pages/select-listers/select-listers-routing.module';
import { SelectTopicsRoutingModule } from './pages/shared-pages/select-topics/select-topics-routing.module';
import { HomeRoutingModule } from './pages/home/home-routing.module';
import { ChatRoutingModule } from './pages/chat/chat-routing.module';
import { SearchRoutingModule } from './pages/search/search-routing.module';
import { MyProfileRoutingModule } from './pages/my-profile/my-profile-routing.module';
import { ListsRoutingModule } from './pages/lists/lists-routing.module';
import {SettingsRoutingModule} from "./pages/settings/settings-routing.module";
import {PrivacyPolicyComponent} from "./pages/shared-pages/privacy-policy/privacy-policy.component";
import {PrivacyPolicyRoutingModule} from "./pages/shared-pages/privacy-policy/privacy-policy-routing.module";
import {
  TermsAndConditionsRoutingModule
} from "./pages/shared-pages/terms-and-conditions/terms-and-conditions-routing.module";
import {
  SuccessActionPageRoutingRoutingModule
} from "./pages/shared-pages/success-action-page/success-action-page-routing.module";

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PAGES_AND_PATHS.login.pagePath
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        LoginRoutingModule,
        ForgotPasswordRoutingModule,
        CreateCompanyAccountRoutingModule,
        CreateAccountRoutingModule,
        AddAccountDetailsRoutingModule,
        VerifyAccountRoutingModule,
        CheckYourEmailRoutingModule,
        SelectLanguageRoutingModule,
        SelectListersRoutingModule,
        SelectTopicsRoutingModule,
        HomeRoutingModule,
        ChatRoutingModule,
        SearchRoutingModule,
        MyProfileRoutingModule,
        ListsRoutingModule,
        SettingsRoutingModule,
        PrivacyPolicyRoutingModule,
        TermsAndConditionsRoutingModule,
        SuccessActionPageRoutingRoutingModule,
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
