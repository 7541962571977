import { Component, Input, OnInit } from '@angular/core';
import { DeleteChatModalComponent } from '../delete-chat-modal/delete-chat-modal.component';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import { ChatInfoComponent } from '../chat-info/chat-info.component';
import { ActivatedRoute } from '@angular/router';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { Conversation, SingleConversationMessage } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { FormatMessageTimestampModule } from 'src/app/pipes/format-message-timestamp/format-message-timestamp.module';
import { ShouldDisplayDateModule } from 'src/app/pipes/should-display-date/should-display-date.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-chat-content',
  standalone: true,
  imports: [
    NgIf,
    DeleteChatModalComponent,
    ChatInfoComponent,
    NgForOf,
    FormatMessageTimestampModule,
    NgClass,
    ShouldDisplayDateModule,
    FormsModule
  ],
  templateUrl: './chat-content.component.html',
})
export class ChatContentComponent implements OnInit {
  @Input() isNewChatActive = false;
  isDeleteModalOpen = false;
  isChatInfoOpen = false;
  chatInfoButtonText = 'Chat info';
  conversationId!: number;
  loadingOlderMessages = false;
  messages: Array<SingleConversationMessage> = [];
  conversation!: Conversation;
  pagination: Partial<TlPagination> = {};
  pageNum = 1;
  message: string = '';
  isInputFocused = false;

  constructor(
    private actRoute: ActivatedRoute,
    private chatHelperService: ChatHelperService,
    private chatHttpService: ChatHttpService
  ) {
  }

  ngOnInit(): void {
    this.chatHelperService.selectedConversationId$.subscribe(conversationId => {
      this.conversationId = conversationId;
      if (this.conversationId !== null && this.conversationId !== 0) {
        this.getSingleConversation(this.conversationId);
      }
    });
  }

  openCloseChatInfo() {
    this.isChatInfoOpen = !this.isChatInfoOpen;
    this.chatInfoButtonText = this.isChatInfoOpen ? 'Exit chat info' : 'Chat info';
  }

  openDeleteModal() {
    this.isDeleteModalOpen = true;
  }

  getSingleConversation(conversationId: number): void {
    this.loadingOlderMessages = true;
    this.chatHttpService.formattedGetSingleConversation(conversationId, this.pageNum).then((response: {
      conversation: Conversation,
      data: Array<SingleConversationMessage>,
      pagination: TlPagination
    }) => {
      this.conversation = response.conversation;
      this.pagination = response.pagination;
      // if (this.isEmojiClicked) {
      //   this.messages = [];
      // }
      console.log('single conversation', response)
      if (this.pageNum === 1) {
        this.messages = response.data.reverse();
      } else {
        this.messages = [...response.data.reverse(), ...this.messages];
      }

      this.loadingOlderMessages = false;
      // this.isEmojiClicked = false;
    }, error => {
      console.error('Failed to load messages', error);
      this.loadingOlderMessages = false;
    });
  }

  onInputFocus(isFocused: boolean) {
    this.isInputFocused = isFocused;
  }

  sendMessage() {
    const updatedChat = {
      type: 'text',
      body: this.message
    };
    if (this.message !== '') {
      this.updateChat(updatedChat);
    }
  }

  updateChat(formData: any) {
    const socketId = localStorage.getItem('socketId');
    this.chatHttpService.updateConversation(this.conversationId, formData, socketId).subscribe(updatedChat => {
      this.messages.push(updatedChat.data);
      this.message = '';
    });
  }

}
