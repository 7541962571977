<div class="wrapper-chat-settings">
  <div class="chat-settings-info">
    <div class="my-info-chat">
      <div class="chat-settings-avatar">
        <img
          [src]="(conversation.profile_photo !== undefined && conversation.profile_photo !== null) ? conversation.profile_photo : 'https://www.perfocal.com/blog/content/images/2021/01/Perfocal_17-11-2019_TYWFAQ_100_standard-3.jpg'"
          alt="">
      </div>
      <span>{{conversation.other_participants[0].username}}</span>
      <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
    </div>
    <div class="btns-chat-settings">
      <div class="gray-btn-img">
        <span>View profile</span>
        <img src="../../../../../assets/icons/outline/icon-user.svg" alt="">
      </div>
      <div class="gray-btn-img">
        <span>Search in conversation</span>
        <img src="../../../../../assets/icons/outline/icon-search.svg" alt="">
      </div>
    </div>
  </div>
  <div class="wrapper-custom-tabs">
    <div class="custom-tab" (click)="selectTab('media')" [ngClass]="{'active': tabSelected === 'media'}">
      <img src="../../../../../assets/icons/outline/icon-posts.svg" alt="">
      <span>Media</span>
    </div>
    <div class="custom-tab" (click)="selectTab('pdfs')" [ngClass]="{'active': tabSelected === 'pdfs'}">
      <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
      <span>PDF's</span>
    </div>
  </div>
  <div *ngIf="tabSelected === 'media'">
    <app-chat-info-media [conversationId]="conversationId"></app-chat-info-media>
  </div>
  <div *ngIf="tabSelected === 'pdfs'">
    <app-chat-info-pdfs [conversationId]="conversationId"></app-chat-info-pdfs>
  </div>
</div>