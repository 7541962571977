import { Component } from '@angular/core';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';

@Component({
  selector: 'tl-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  PAGES_AND_PATHS = PAGES_AND_PATHS;
}
