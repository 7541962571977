<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmitChangePasswordForm()" class="wrapper-change-password-page">
  <div class="tl-d-flex tl-flex-column">
    <span class="title-settings-page">Change password</span>
    <span class="subtitle-settings-page">Create a new password for your account.</span>
  </div>
  <div class="wrapper-input" [ngClass]="{'error': changePasswordForm.controls['current_password'] | formControlErrorMessages: null: formTriedToSubmit}">
    <span class="label-input">Current password</span>
    <div class="custom-input">
      <input
        [type]="isCurrentPasswordShow ? 'text' : 'password'"
        formControlName="current_password"
        placeholder="Enter current password">
      <img *ngIf="!isCurrentPasswordShow" (click)="isCurrentPasswordShow = true" class="icon-input" src="../assets/icons/outline/icon-outline-eye.svg" alt="">
      <img *ngIf="isCurrentPasswordShow" (click)="isCurrentPasswordShow = false" class="icon-input" src="../assets/icons/outline/icon-outline-eye-with-slash.svg" alt="">
    </div>
    <div *ngIf="changePasswordForm.controls['current_password'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{changePasswordForm.controls['current_password'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
    <div *ngIf="isCurrentPasswordErrorShow" class="wrapper-error-message-icon">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>Your password is incorrect</span>
    </div>
  </div>
  <div class="wrapper-input" [ngClass]="{'error': changePasswordForm.controls['new_password'] | formControlErrorMessages: null: formTriedToSubmit}">
    <span class="label-input">New password</span>
    <div class="custom-input">
      <input
        [type]="isNewPasswordShow ? 'text' : 'password'"
        formControlName="new_password"
        placeholder="Enter new password">
      <img *ngIf="!isNewPasswordShow" (click)="isNewPasswordShow = true" class="icon-input" src="../assets/icons/outline/icon-outline-eye.svg" alt="">
      <img *ngIf="isNewPasswordShow" (click)="isNewPasswordShow = false" class="icon-input" src="../assets/icons/outline/icon-outline-eye-with-slash.svg" alt="">
    </div>
    <div *ngIf="(changePasswordForm.controls['new_password'] | formControlErrorMessages: null: formTriedToSubmit) === 'Minimum of 8 characters'" class="error-message">
      <span>Your password needs to have:</span>
    </div>
    <div *ngIf="changePasswordForm.controls['new_password'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{changePasswordForm.controls['new_password'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
    <div *ngIf="changePasswordForm.controls['new_password'].valid" class="error-message purple" >
      <span>Your password needs to have:</span>
    </div>
    <div *ngIf="changePasswordForm.controls['new_password'].valid" class="wrapper-error-message purple">
      <img src="../assets/icons/outline/icon-check-circle.svg" alt="">
      <span>Minimum of 8 characters</span>
    </div>
  </div>
  <div class="wrapper-input" [ngClass]="{'error': changePasswordForm.controls['password_confirmation'] | formControlErrorMessages: null: formTriedToSubmit}">
    <span class="label-input">Confirm new password</span>
    <div class="custom-input">
      <input
        [type]="isConfirmPasswordShow ? 'text' : 'password'"
        formControlName="password_confirmation"
        placeholder="Confirm new password">
      <img *ngIf="!isConfirmPasswordShow" (click)="isConfirmPasswordShow = true" class="icon-input" src="../assets/icons/outline/icon-outline-eye.svg" alt="">
      <img *ngIf="isConfirmPasswordShow" (click)="isConfirmPasswordShow = false" class="icon-input" src="../assets/icons/outline/icon-outline-eye-with-slash.svg" alt="">
    </div>
    <div *ngIf="changePasswordForm.controls['password_confirmation'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{changePasswordForm.controls['password_confirmation'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="wrapper-submit-settings">
    <button type="submit" class="custom-btn custom-btn--primary">
      <span>Change password</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
