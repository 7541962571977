<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-my-profile-page">
    <tl-header></tl-header>
    <div class="wrapper-settings-page">
      <div class="header-settings">
        <span>My profile</span>
      </div>
      <div class="content-settings">
        <div class="wrapper-content-settings-footer">
          <div class="content-settings-footer-left">
            <a href="">Privacy Policy</a>
            <a href="">Terms & Conditions</a>
          </div>
          <div class="content-settings-footer-right">
            <span>© 2024 TheLists</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
