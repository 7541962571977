<swiper-container #swiper>
  <swiper-slide class="wrapper-onboarding">
    <div class="wrapper-image-onboarding">
      <img src="../assets/images/onboarding-image1.png" alt="">
      <div class="wrapper-steps-btn">
        <div class="step-btn active"></div>
        <div class="step-btn" (click)="goToSlide(1)"></div>
        <div class="step-btn" (click)="goToSlide(2)"></div>
      </div>
      <div class="wrapper-onboarding-text">
        <h1>Create your own lists with your favorite items</h1>
        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</h6>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide class="wrapper-onboarding">
    <div class="wrapper-image-onboarding">
      <img src="../assets/images/onboarding-image2.png" alt="">
      <div class="wrapper-steps-btn">
        <div class="step-btn" (click)="goToSlide(0)"></div>
        <div class="step-btn active"></div>
        <div class="step-btn" (click)="goToSlide(2)"></div>
      </div>
      <div class="wrapper-onboarding-text">
        <h1>Create your own lists with your favorite items</h1>
        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</h6>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide class="wrapper-onboarding">
    <div class="wrapper-image-onboarding">
      <img src="../assets/images/onboarding-image3.png" alt="">
      <div class="wrapper-steps-btn">
        <div class="step-btn" (click)="goToSlide(0)"></div>
        <div class="step-btn" (click)="goToSlide(1)"></div>
        <div class="step-btn active"></div>
      </div>
      <div class="wrapper-onboarding-text">
        <h1>Create your own lists with your favorite items</h1>
        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</h6>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
