import { Component } from '@angular/core';

@Component({
  selector: 'tl-insights',
  standalone: true,
  imports: [],
  templateUrl: './insights.component.html',
})
export class InsightsComponent {

}
