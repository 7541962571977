import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { ChatContentComponent } from './components/chat-content/chat-content.component';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    NgIf,
    ChatListComponent,
    ChatContentComponent
  ],
  templateUrl: './chat.component.html',
})
export class ChatComponent {
  isNewChatActive = false;

  setIsNewCHatActiveFlag(isActive: boolean) {
    this.isNewChatActive = isActive;
  }

}
