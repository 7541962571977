import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GeneralHttpHelperService} from "../../helpers/general-http-helper.service";

const SETTINGS_API_ROUTES = {
  DELETE_ACCOUNT: 'user/profile',
}
@Injectable({
  providedIn: 'root'
})
export class SettingsHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) {

  }

  private deleteAccountReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(SETTINGS_API_ROUTES.DELETE_ACCOUNT, bodyParams) as any;
  }
  deleteAccount(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteAccountReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
