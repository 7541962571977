import { NgForOf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { NewConversation } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-new-conversation',
  standalone: true,
  imports: [
    NgForOf,
    FormsModule
  ],
  templateUrl: './new-conversation.component.html',
})
export class NewConversationComponent implements OnInit {
  users: any[] = [];
  pageNum = 1;
  pagination!: TlPagination;
  selectedUsers = new Set<number>();
  searchTerm = '';
  private searchSubject = new Subject<string>();

  constructor(
    private chatService: ChatHttpService
  ) { }

  ngOnInit() {
    this.getUsers();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getUsers(searchTerm)))
    ).subscribe(response => {
      this.users = response.data.map(user => ({
        ...user,
        isSelected: this.selectedUsers.has(user.id)
      }));
      console.log(this.selectedUsers)
      this.pagination = response.pagination;
    });
  }

  getUsers(searchTerm?: string) {
    return from(
      this.chatService.formattedgetUsersForChat(this.pageNum, searchTerm).then((response: {
        data: Array<any>,
        pagination: TlPagination
      }) => {
        this.users = [...this.users, ...response.data];
        this.pagination = response.pagination;
        return response;
      }));
  }

  async createChat() {
    const selectedEmails = Array.from(this.selectedUsers);
    const chatData: NewConversation = {
      participants: selectedEmails,
      is_group: selectedEmails.length > 1
    };

    try {
      const response = await this.chatService.createChat(chatData);
      // chatData.is_group ? this.router.navigate([PAGES_AND_PATHS.groupChatConversation.pagePath, response.data.id]) : this.router.navigate([PAGES_AND_PATHS.singleChatConversation.pagePath, response.data.id]);
    } catch (error) {
      console.error('Error creating chat', error);
    }
  }

  toggleSelection(userId: number) {
    if (this.selectedUsers.has(userId)) {
      this.selectedUsers.delete(userId);
    } else {
      this.selectedUsers.add(userId);
    }
  }

  isSelected(userId: number): boolean {
    return this.selectedUsers.has(userId);
  }

  onSearchChange(event: any): void {
    console.log('boo')
    const keyword = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(keyword);
  }

}
