// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_ENDPOINT: 'https://dev-api.thelysts.com/api/v1/',
  PUSHER_API_ENDPOINT: 'https://dev-api.thelysts.com/broadcasting/auth',
  PUSHER_APP_ID: '1851305',
  PUSHER_APP_KEY: '17ec7569d2338be09e67',
  PUSHER_SECRET_KEY: '543ef6e44629403559cc',
  PUSHER_APP_CLUSTER: 'eu',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
