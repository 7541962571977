import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-delete-chat-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-chat-modal.component.html',
})
export class DeleteChatModalComponent {
  @Input() conversationId!: number;
  @Output() closeDeleteChatModal =  new EventEmitter<boolean>();
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() deleteChat = new EventEmitter<boolean>();

  constructor(
    private chatsHttpService: ChatHttpService
  ) { }

  ngOnInit() {}

  delete() {
    this.chatsHttpService.deleteConversation(this.conversationId).subscribe(res => {
      console.log('deleteConversation', res);
      this.deleteChat.emit(true);
    });
  }

  cancel(){
    this.closeModal.emit(true);
  }

}
