import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {Countries} from '../../../constants/general-variables';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationHelperService} from '../../../services/helpers/authentication-helper.service';
import {AuthenticationHttpService} from '../../../services/http-services/authentication/authentication-http.service';
import {ToastrService} from "ngx-toastr";
import {UiHelperService} from "../../../services/helpers/ui-helper.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'tl-add-account-details',
  templateUrl: './add-account-details.component.html',
  providers: [DatePipe]
})
export class AddAccountDetailsComponent implements OnInit {
  completeRegistrationForm!: FormGroup;
  isSpinnerActive = false;
  isAreaCodeOpen =  false;
  selectedCountry: any = null;
  showCountryDropdown = false;
  searchQuery = '';
  selectedArea = Countries[0];
  filteredCountries = Countries;
  formTriedToSubmit = false;
  @ViewChild('areaCodeBtn') areaCodeBtn: ElementRef | undefined;
  @ViewChild('countrySearch') countrySearch: ElementRef | undefined;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly Countries = Countries;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toastService: ToastrService,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private uiHelperService: UiHelperService,
              private authHelperService: AuthenticationHelperService,
              private authHttpService: AuthenticationHttpService) { }

  ngOnInit(): void {
    this.buildForm();
    this.completeRegistrationForm.controls.gender.valueChanges.subscribe(val => {
      const genderOther = this.completeRegistrationForm.get('genderOther');
      if (val === 'other') {
        genderOther?.setValidators([Validators.required]);
      } else {
        genderOther?.clearValidators();
      }
      genderOther?.updateValueAndValidity();
    });
    this.listenToClickAreaCode();
  }

  listenToClickAreaCode(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.areaCodeBtn, this.areaCodeBtn, e)) {
        this.isAreaCodeOpen = false;
      }
    });
  }

  buildForm(): void {
    this.completeRegistrationForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      gender: ['male', [Validators.required]],
      genderOther: [''],
      ethnicity: ['', [Validators.required]],
      date_of_birth: ['', [Validators.required]],
      country: ['', [Validators.required]],
      city: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
    });
  }

  onSelectDateOfBirth(selectedDate: any): void {
    this.completeRegistrationForm.controls.date_of_birth.patchValue(this.datePipe.transform(selectedDate._d, 'dd.MM.yyyy'));
  }

  onSelectCodeArea(country: any): void {
    this.selectedArea = country;
  }

  onSearchCountries(): void {
    this.filteredCountries = Countries.filter(country => {
      return country.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  onBlurSearchbar(): void {
    setTimeout(() => {
      this.showCountryDropdown = false;
    }, 200);
    this.completeRegistrationForm.controls.country.markAsTouched();
  }

  onSelectCountry(country: any): void {
    this.completeRegistrationForm.controls.country.patchValue(country.name);
    this.selectedCountry = country;
    this.searchQuery = country.name;
  }

  removeSelectedCountry(): void {
    this.selectedCountry = null;
    this.completeRegistrationForm.controls.country.patchValue('');
    this.onSearchCountries();
    setTimeout(() => {
      this.countrySearch?.nativeElement.focus();
    }, 200);
  }

  onSubmitCompleteRegistrationForm(): void {
    const successPageDetails = {
      iconPath: '../assets/icons/outline/icon-check.svg',
      title: 'Account has been successfully created',
      description: 'Your account has been successfully created.',
      btnText: 'Continue',
      redirectToUrl: PAGES_AND_PATHS.selectTopics.pagePath
    }
    this.formTriedToSubmit = true;
    if (this.completeRegistrationForm.valid) {
      this.isSpinnerActive = true;
      this.authHttpService.patchUserProfile({...this.completeRegistrationForm.value, phone_number: this.selectedArea.phone_code + ' ' + this.completeRegistrationForm.controls.phone_number.value})
        .then(res => {
          this.isSpinnerActive = false;
          this.authHelperService.setUserData(res);
          this.router.navigate([PAGES_AND_PATHS.successAction.pagePath], {state: successPageDetails});
        }).catch(({error}: {error: { errors: { [key: string]: string }}}) => {
          this.isSpinnerActive = false;
          Object.values(error.errors).forEach(error => this.toastService.error(error));
        });
    }
  }
}
