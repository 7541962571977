import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewConversation } from './chat-http-interfaces';
import { GeneralHttpHelperService } from '../../helpers/general-http-helper.service';

const CHAT_API_ROUTES = {
  CREATE_CONVERSATION: 'chat/conversations',
  GET_CONVERSATIONS: 'chat/conversations'
}

@Injectable({
  providedIn: 'root'
})
export class ChatHttpService {

  constructor(
    private httpClient: HttpClient,
    private generalHelperService: GeneralHttpHelperService
  ) { }

  private createChatReq(chatData: NewConversation): Observable<any> {
    return this.httpClient.post(CHAT_API_ROUTES.CREATE_CONVERSATION, chatData);
  }
  createChat(chatData: NewConversation): Promise<any> {
    return new Promise((resolve, reject) => {
      this.createChatReq(chatData).subscribe({
        next: (res: any) => resolve(res),
        error: (error) => reject(error),
      });
    });
  }

  private getConversations(page: number, searchKeyword?: string): Observable<any[]> {
    let url = CHAT_API_ROUTES.GET_CONVERSATIONS;
    let params = [];
    if (searchKeyword) {
      params.push(`search_keyword=${encodeURIComponent(searchKeyword)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetConversations(page: number, searchKeyword?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getConversations(page, searchKeyword).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getSingleConversation(conversationId: number, pageNum: number, searchKeyword?: string): Observable<any> {
    let url = `chat/conversations/${conversationId}`;
    let params = [];
    if (searchKeyword) {
      params.push(`search_keyword=${encodeURIComponent(searchKeyword)}`);
    }
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any>(url);
  }
  formattedGetSingleConversation(conversationId: number, pageNum: number, searchTerm?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSingleConversation(conversationId, pageNum, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            conversation: res.data.conversation,
            data: res.data.messages.data,
            pagination: this.generalHelperService.returnPagination(res.data.messages)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getGroupConversation(conversationId: number, pageNum: number, searchKeyword?: string): Observable<any> {
    let url = `chat/conversations/${conversationId}`;
    let params = [];
    if (searchKeyword) {
      params.push(`search_keyword=${encodeURIComponent(searchKeyword)}`);
    }
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any>(url);
  }
  formattedGetGroupConversation(conversationId: number, pageNum: number, searchTerm?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getGroupConversation(conversationId, pageNum, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            conversation: res.data.conversation,
            data: res.data.messages.data,
            pagination: this.generalHelperService.returnPagination(res.data.messages)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  updateConversation(conversationId: number, chatData: any, socketID?: any): Observable<any> {
    let headers = new HttpHeaders().set('X-Socket-Id', socketID ?? '');
    return this.httpClient.post<any>(`chat/conversations/${conversationId}/messages`, chatData, {
      headers: headers
    });
  }

  // GET DUMMY USERS
  private getDummyUsers(): Observable<any> {
    return this.httpClient.get('10-users');
  }
  formattedGetDummyUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDummyUsers().subscribe({
        next: (res: any) => {
          resolve({
            data: Object.entries(res.data).map(([email, id]) => ({
              email: email,
              id: id
            }))
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  deleteConversation(conversationId: number): Observable<void> {
    return this.httpClient.delete<void>(`chat/conversations/${conversationId}`);
  }

  reactOnMessage(messageId: number, reqObj: any, socketID?: any): Observable<void> {
    let headers = new HttpHeaders().set('X-Socket-Id', socketID ?? '');
    return this.httpClient.post<void>(`chat/conversations/message-reactions/${messageId}`, reqObj, {
      headers: headers
    });
  }

  // GET MEDIA
  private getSingleConversationMedia(conversationId: number, pageNum: number): Observable<any> {
    let params = new HttpParams().set('page', pageNum);
    return this.httpClient.get(`chat/conversations/${conversationId}/get_media`, { params });
  }
  formattedGetSingleConversationMedia(conversationId: number, pageNum: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSingleConversationMedia(conversationId, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          })
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  // GET PDFS
  private getSingleConversationPDFs(conversationId: number, pageNum: number): Observable<any> {
    let params = new HttpParams().set('page', pageNum);
    return this.httpClient.get(`chat/conversations/${conversationId}/get_pdfs`, { params });
  }
  formattedGetSingleConversationPDFs(conversationId: number, pageNum: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSingleConversationPDFs(conversationId, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          })
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  getChatCount(): Observable<any> {
    return this.httpClient.get<any>('user/get_unread_conversations_count');
  }

  private getUsersForChat(pageNum: number, searchKeyword?: string): Observable<any> {
    let url = 'user/get_users_for_chat';
    let params = [];
    if (searchKeyword) {
      params.push(`search_keyword=${encodeURIComponent(searchKeyword)}`);
    }
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any>(url);
  }
  formattedgetUsersForChat(pageNum: number, searchTerm?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getUsersForChat(pageNum, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

}
