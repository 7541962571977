<div class="wrapper-footer-page">
    <!-- TODO JS - Dodajes klasu "active" -->
    <div [routerLink]="[PAGES_AND_PATHS.home.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.home.pagePath}">
        <img src="../../../assets/icons/outline/icon-home.svg" alt="">
        <span>Home</span>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.search.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.search.pagePath}">
        <img src="../../../assets/icons/outline/icon-search.svg" alt="">
        <span>Search</span>
    </div>
    <div class="tab-footer">
        <button class="custom-btn custom-btn&#45;&#45;circle-primary large">
            <img src="../../../assets/icons/outline/icon-plus.svg" alt="">
        </button>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.lists.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.lists.pagePath}">
        <img src="../../../assets/icons/outline/icon-lists.svg" alt="">
        <span>Lists</span>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.profile.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.profile.pagePath}">
        <div class="wrapper-tab-profile">
            <img src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1413502914.1724630400&semt=ais_hybrid"
                alt="">
            <span>Ronald Lara</span>
        </div>
    </div>
</div>