import { NgForOf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SafeUrlPipe } from 'src/app/pipes/safe-url/safe-url.pipe';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-chat-info-media',
  standalone: true,
  imports: [
    NgForOf,
    SafeUrlPipe
  ],
  templateUrl: './chat-info-media.component.html',
})
export class ChatInfoMediaComponent implements OnChanges {
  @Input() conversationId: number = 0;
  @Input() chatType: string = 'single';
  pageNum = 1;
  mediaFiles: any[] = [];
  pagination: Partial<TlPagination> = {};
  
  constructor(
    private chatsHttpService: ChatHttpService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['conversationId'].currentValue) {
      this.chatType === 'single' ? this.getSingleMedia() : this.getGroupMedia();
    }
  }

  getSingleMedia() {
    this.chatsHttpService.formattedGetSingleConversationMedia(this.conversationId, this.pageNum).then((response: any) => {
      console.log('single conversation media', response);
      this.mediaFiles = [...this.mediaFiles, ...response.data];
      this.pagination = response.pagination;
    });
  }

  getGroupMedia() {

  }

}
