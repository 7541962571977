<div class="wrapper-chat-all-conversation-header">
    <div class="back-btn-chat">
        <button (click)="back()" class="custom-btn custom-btn--circle-outline">
            <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </button>
        <span>{{title}}</span>
    </div>
    <button *ngIf="!isUserListOpen" (click)="openUserList()" class="custom-btn custom-btn--circle-primary">
        <img src="../../../assets/icons/outline/icon-plus.svg" alt="">
    </button>
</div>
<div *ngIf="!isUserListOpen" class="wrapper-chat-all-conversation-content">
    <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
        <div (click)="resetSearch()" *ngIf="searchTerm.length > 0" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
    </div>
    <div *ngIf="conversations?.length" class="wrapper-all-conversation-list">
        <div *ngFor="let chat of conversations" (click)="openConversation(chat.id)" class="user-item-list"
            [ngClass]="chat.my_unread_count > 0 ? 'unread' : ''" [ngClass]="{
                'unread': chat.my_unread_count > 0,
                'active': selectedConversationId === chat.id
              }">
            <div class="user-item-list-left">
                <div class="wrapper-avatar-item">
                    <img [src]="chat?.image ? chat?.image : 'https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1413502914.1724630400&semt=ais_hybrid'"
                        alt="">
                </div>
                <div class="wrapper-name-message">
                    <div class="wrapper-name">
                        <span class="length-character">{{chat | setChatUsernames}}</span>
                        <!--                    <img src="../../../assets/icons/fill/icon-badge.svg" alt="">-->
                    </div>
                    <span class="message length-character">
                        {{chat.latest_message.body}}
                    </span>
                </div>
            </div>
            <div class="user-item-list-right">
                <div class="item-user-time">
                    <span>{{chat.updated_at | date:'HH:mm'}}</span>
                </div>
                <div class="unread-message">
                    <span>{{chat.my_unread_count}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-new-conversation *ngIf="isUserListOpen"></app-new-conversation>
