<div class="header-page">
    <button (click)="goBack()" class="custom-btn custom-btn--circle-outline">
        <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
    </button>
    <div class="options-header-right">
        <button class="custom-btn custom-btn--circle-outline">
            <img src="../../../assets/icons/outline/icon-notifications.svg" alt="">
        </button>
        <button class="custom-btn custom-btn--circle-outline" [routerLink]="PAGES_AND_PATHS.chat.pagePath">
            <img src="../../../assets/icons/outline/icon-chat.svg" alt="">
        </button>
        <div class="wrapper-btn-dropdown" [ngClass]="{'open-dropdown': isDropdownOpened}">
            <button #dropdownBtn (click)="isDropdownOpened = !isDropdownOpened" class="custom-btn custom-btn--circle-fill ">
                <img src="../../../assets/icons/outline/icon-menu.svg" alt="">
            </button>
            <div class="dropdown-content-btn">
                <div class="item-dropdown-btn" [routerLink]="PAGES_AND_PATHS.settings.pagePath">
                    <img src="../../../assets/icons/outline/icon-settings.svg" alt="">
                    <span>Settings</span>
                </div>
                <div class="item-dropdown-btn" (click)="logout()">
                    <img src="../../../assets/icons/outline/icon-logout.svg" alt="">
                    <span>Log out</span>
                </div>
            </div>
        </div>
    </div>
</div>
