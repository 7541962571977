<div class="wrapper-chat-conversation-header">
  <div class="header-chat-conversation" *ngIf="!isNewChatActive">
    <div *ngIf="conversation">
      <div *ngIf="!isChatInfoOpen" class="wrapper-chat-conversation-left">
        <div class="avatar-image">
          <img src="https://www.perfocal.com/blog/content/images/2021/01/Perfocal_17-11-2019_TYWFAQ_100_standard-3.jpg"
            alt="">
        </div>
        <span *ngIf="conversation.other_participants !== null">{{conversation.other_participants[0].username}}</span>
        <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
      </div>
    </div>
    <div class="wrapper-chat-conversation-right">
      <button class="custom-btn custom-btn--circle-outline" (click)="openDeleteModal()">
        <img src="../../../../../assets/icons/outline/icon-delete.svg" alt="">
      </button>
      <button class="custom-btn custom-btn--outline"
        (click)="openCloseChatInfo()"><span>{{chatInfoButtonText}}</span></button>
    </div>
  </div>
</div>
<div *ngIf="messages.length && !isChatInfoOpen" class="wrapper-chat-conversation-content">
  <div class="chat-conversation-content">
    <div *ngFor="let message of messages; let i = index">
      <div [ngClass]="{ 'my-message': message.is_mine }" class="wrapper-message">
        <div *ngIf="messages | shouldDisplayDate: i" class="time-message old">
          <span>{{message.updated_at | formatMessageTimestamp}}</span>
        </div>
        <div class="message">
          <div class="image-message" *ngIf="message.type === 'image' && message?.image !== null" >
<!--              <img src="https://letsenhance.io/static/03620c83508fc72c6d2b218c7e304ba5/11499/UpscalerAfter.jpg" alt="">-->
               <img [src]="message?.image?.url" alt="">
          </div>
          <div class="video-message" *ngIf="message.type === 'video' && message.video !== null">
            video
          </div>
          <div class="attachment-message" *ngIf="message.type === 'attachment' && message.attachment !== null && message.attachment !== undefined">
            <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>{{message.attachment.name + '.' + message.attachment.extension}}</span>
          </div>
          <div class="text-message" *ngIf="message.type === 'text' && message?.body !== null">
            <span>{{message?.body}}</span>
          </div>
        </div>
        <div class="time-message">
          <span>22:52</span>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-conversation-footer">
    <div class="wrapper-upload-icons">
      <div class="wrapper-upload-file">
        <label>
          <img src="../../../../../assets/icons/outline/icon-camera.svg" alt="">
          <input type="file" class="upload-custom" />
        </label>
      </div>
      <div class="wrapper-upload-file">
        <label>
          <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
          <input type="file" class="upload-custom" />
        </label>
      </div>
    </div>
    <div class="wrapper-input-chat">
      <input #messageInput placeholder="Message..." [(ngModel)]="message" (onFocus)="onInputFocus(true)"
        (onBlur)="onInputFocus(false)" (keydown.enter)="sendMessage()">
    </div>
    <div class="wrapper-send-btn">
      <button class="custom-btn custom-btn--circle-primary" (click)="sendMessage()">
        <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
      </button>
    </div>
  </div>
</div>
<app-delete-chat-modal *ngIf="isDeleteModalOpen" [conversationId]="0"
  (closeDeleteChatModal)="isDeleteModalOpen = false"></app-delete-chat-modal>
<app-chat-info *ngIf="isChatInfoOpen" [conversation]="conversation"
  (closeChatInfoModal)="isChatInfoOpen = false"></app-chat-info>
