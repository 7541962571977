<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-my-profile-page">
    <tl-header></tl-header>
    <div class="wrapper-settings-page">
      <div class="header-settings">
        <span>Settings</span>
      </div>
      <div class="content-settings">
        <div class="sidebar-settings-left">
          <div class="wrapper-settings-sidebar">
            <div class="wrapper-item-settings-sidebar" (click)="activeTab = SettingsTabs.insights" [ngClass]="{'active': activeTab === SettingsTabs.insights}">
              <img src="../../../assets/icons/outline/icon-diagram.svg" alt="">
              <span>Insights</span>
            </div>
            <div (click)="isAccountDropdownOpen = !isAccountDropdownOpen; activeTab = SettingsTabs.changeUsername" class="wrapper-item-settings-sidebar" [ngClass]="{'open': isAccountDropdownOpen, 'active': activeTab === SettingsTabs.changeUsername || activeTab === SettingsTabs.changeEmail || activeTab === SettingsTabs.changePhone || activeTab === SettingsTabs.deleteAccount}">
              <div class="wrapper-dropdown-settings-sidebar">
                <div class="tl-d-flex tl-align-center">
                  <img src="../../../assets/icons/outline/icon-user.svg" alt="">
                  <span>Account</span>
                </div>
                <img class="icon-dropdown" src="../../../assets/icons/icon-arrow-left.svg" alt="">
              </div>
            </div>
            <div *ngIf="isAccountDropdownOpen" class="wrapper-dropdown-sidebar">
              <div class="dropdown-sidebar-item" (click)="activeTab = SettingsTabs.changeUsername" [ngClass]="{'active': activeTab === SettingsTabs.changeUsername}">
                <span>Change username</span>
              </div>
              <div class="dropdown-sidebar-item" (click)="activeTab = SettingsTabs.changeEmail" [ngClass]="{'active': activeTab === SettingsTabs.changeEmail}">
                <span>Change email address</span>
              </div>
              <div class="dropdown-sidebar-item" (click)="activeTab = SettingsTabs.changePhone" [ngClass]="{'active': activeTab === SettingsTabs.changePhone}">
                <span>Change phone number</span>
              </div>
              <div class="dropdown-sidebar-item" (click)="activeTab = SettingsTabs.deleteAccount" [ngClass]="{'active': activeTab === SettingsTabs.deleteAccount}">
                <span>Delete account</span>
              </div>
            </div>
            <div class="wrapper-item-settings-sidebar" (click)="activeTab = SettingsTabs.languages" [ngClass]="{'active': activeTab === SettingsTabs.languages}">
              <img src="../../../assets/icons/outline/icon-language.svg" alt="">
              <span>Languages</span>
            </div>
            <div class="wrapper-item-settings-sidebar" (click)="activeTab = SettingsTabs.subscriptionPlan" [ngClass]="{'active': activeTab === SettingsTabs.subscriptionPlan}">
              <img src="../../../assets/icons/outline/icon-dollar.svg" alt="">
              <span>Subscription plans</span>
            </div>
            <div class="wrapper-item-settings-sidebar" (click)="activeTab = SettingsTabs.changePassword" [ngClass]="{'active': activeTab === SettingsTabs.changePassword}">
              <img src="../../../assets/icons/outline/icon-security.svg" alt="">
              <span>Change password</span>
            </div>
          </div>
        </div>
        <div class="wrapper-content-settings-right">
          <div class="content-settings-right">
            <tl-insights *ngIf="activeTab === SettingsTabs.insights"></tl-insights>
            <tl-change-username *ngIf="activeTab === SettingsTabs.changeUsername"></tl-change-username>
            <tl-change-email *ngIf="activeTab === SettingsTabs.changeEmail"></tl-change-email>
            <tl-change-phone *ngIf="activeTab === SettingsTabs.changePhone"></tl-change-phone>
            <tl-delete-account *ngIf="activeTab === SettingsTabs.deleteAccount"></tl-delete-account>
            <tl-languages *ngIf="activeTab === SettingsTabs.languages"></tl-languages>
            <tl-subscription-plan *ngIf="activeTab === SettingsTabs.subscriptionPlan"></tl-subscription-plan>
            <tl-change-password *ngIf="activeTab === SettingsTabs.changePassword"></tl-change-password>
          </div>
        </div>
        <div class="wrapper-content-settings-footer">
          <div class="content-settings-footer-left">
            <a href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.privacyPolicy.pagePath]">Privacy Policy</a>
            <a href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.termsAndConditions.pagePath]">Terms & Conditions</a>
          </div>
          <div class="content-settings-footer-right">
            <span>© 2024 TheLists</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
