export const PAGES_AND_PATHS = {
  selectLanguage: {
    pageName: 'SELECT_LANGUAGE',
    pageInRouting: 'select-language',
    pagePath: '/select-language',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/select-language']
  },
  login: {
    pageName: 'LOGIN',
    pageInRouting: 'login',
    pagePath: '/login',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/login']
  },
  forgotPassword: {
    pageName: 'FORGOT_PASSWORD',
    pageInRouting: 'forgot-password',
    pagePath: '/forgot-password',
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: false,
    pagePathWithoutParams: ['/forgot-password']
  },
  checkYourEmail: {
    pageName: 'CHECK_YOUR_EMAIL',
    pageInRouting: 'check-your-email/:email',
    pagePath: '/check-your-email',
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: false,
    pagePathWithoutParams: ['/check-your-email']
  },
  createAccount: {
    pageName: 'CREATE_ACCOUNT',
    pageInRouting: 'create-account',
    pagePath: '/create-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/create-account']
  },
  createCompanyAccount: {
    pageName: 'CREATE_COMPANY_ACCOUNT',
    pageInRouting: 'create-company-account',
    pagePath: '/create-company-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/create-company-account']
  },
  verifyAccount: {
    pageName: 'VERIFY_ACCOUNT',
    pageInRouting: 'verify-account',
    pagePath: '/verify-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/verify-account']
  },
  verifyAccountCompany: {
    pageName: 'VERIFY_ACCOUNT',
    pageInRouting: 'verify-company-account',
    pagePath: '/verify-company-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/verify-company-account']
  },
  chat: {
    pageName: 'CHAT',
    pageInRouting: 'chat',
    pagePath: '/chat',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/chat'],
    showFooter: true
  },
  chatSingleConversation: {
    pageName: 'CHAT',
    pageInRouting: 'chat/:id',
    pagePath: '/chat',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/chat'],
    showFooter: true
  },
  completeRegistration: {
    pageName: 'complete-registration',
    pageInRouting: 'complete-registration',
    pagePath: '/complete-registration',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/complete-registration']
  },
  companySubscription: {
    pageName: 'complete-subscription',
    pageInRouting: 'complete-subscription',
    pagePath: '/complete-subscription',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/complete-subscription']
  },
  verificationSuccessful: {
    pageName: 'VERIFICATION_SUCCESSFUL',
    pageInRouting: 'verification-successful',
    pagePath: '/verification-successful',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/verification-successful']
  },
  selectTopics: {
    pageName: 'SELECT_TOPICS',
    pageInRouting: 'select-topics',
    pagePath: '/select-topics',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/select-topics']
  },
  selectListers: {
    pageName: 'SELECT_LISTERS',
    pageInRouting: 'select-listers',
    pagePath: '/select-listers',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/select-listers']
  },
  accountSuccessfullyCreated: {
    pageName: 'ACCOUNT_SUCCESSFULLY_CREATED',
    pageInRouting: 'account-successfully-created',
    pagePath: '/account-successfully-created',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/account-successfully-created']
  },
  companyAccountSuccessfullyCreated: {
    pageName: 'COMPANY_ACCOUNT_SUCCESSFULLY_CREATED',
    pageInRouting: 'company-account-successfully-created',
    pagePath: '/company-account-successfully-created',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/company-account-successfully-created']
  },
  successAction: {
    pageName: 'success-action',
    pageInRouting: 'success-action',
    pagePath: '/success-action',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/success-action']
  },
  home: {
    pageName: 'HOME',
    pageInRouting: 'home',
    pagePath: '/home',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/home'],
    showFooter: true
  },
  search: {
    pageName: 'SEARCH',
    pageInRouting: 'search',
    pagePath: '/search',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/search'],
    showFooter: true
  },
  lists: {
    pageName: 'LISTS',
    pageInRouting: 'lists',
    pagePath: '/lists',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists'],
    showFooter: true
  },
  createList: {
    pageName: 'CREATE_LIST',
    pageInRouting: 'create-list',
    pagePath: '/create-list',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['create-list'],
    showFooter: true
  },
  profile: {
    pageName: 'PROFILE',
    pageInRouting: 'profile',
    pagePath: '/profile',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/profile'],
    showFooter: true
  },
  settings: {
    pageName: 'SETTINGS',
    pageInRouting: 'settings',
    pagePath: '/settings',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/settings'],
    showFooter: true
  },
  privacyPolicy: {
    pageName: 'PRIVACY_POLICY',
    pageInRouting: 'privacy-policy',
    pagePath: '/privacy-policy',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/privacy-policy']
  },
  termsAndConditions: {
    pageName: 'TERMS_AND_CONDITONS',
    pageInRouting: 'terms-and-conditions',
    pagePath: '/terms-and-conditions',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/terms-and-conditions']
  },
};

export const DEFAULT_AUTHENTICATED_PAGE = {
  page: PAGES_AND_PATHS.home
};
