import { Component } from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'tl-subscription-plan',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './subscription-plan.component.html',
})
export class SubscriptionPlanComponent {

}
