import { Component, Input, OnInit } from '@angular/core';
import { ChatInfoMediaComponent } from '../chat-info-media/chat-info-media.component';
import { ChatInfoPdfsComponent } from '../chat-info-pdfs/chat-info-pdfs.component';
import { NgClass, NgIf } from '@angular/common';
import { Conversation } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chat-info',
  standalone: true,
  imports: [
    NgIf,
    ChatInfoMediaComponent,
    ChatInfoPdfsComponent,
    NgClass
  ],
  templateUrl: './chat-info.component.html',
})
export class ChatInfoComponent implements OnInit {
  @Input() conversation!: Conversation;
  tabSelected = 'media';
  conversationId!: number;

  constructor(
    private actRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getId();
  }

  selectTab(tab: string) {
    this.tabSelected = tab;
  }

  getId() {
    this.actRoute.paramMap.subscribe(params => {
      this.conversationId = Number(params.get('id') || '');
    });
  }
}
