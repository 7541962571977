import {Component, OnInit} from '@angular/core';
import {TlPagination} from "../../../interfaces/general-interfaces";
import {AuthenticationHttpService} from "../../../services/http-services/authentication/authentication-http.service";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {Router} from "@angular/router";

@Component({
  selector: 'tl-select-listers',
  templateUrl: './select-listers.component.html',
})
export class SelectListersComponent implements OnInit {
  listers: any[] = [];
  numberOfSelectedListers = 0;
  listersPagination: TlPagination | undefined;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private authHttpService: AuthenticationHttpService,
              private router: Router) { }

  ngOnInit(): void {
    this.getListers();
  }

  getListers(): void {
    const page = this.listersPagination?.currentPage ? this.listersPagination.currentPage + 1 : 1;
    this.authHttpService.getSuggestedListers({page}).then(res => {
      this.listers = [...this.listers, ...res.data];
      this.listersPagination = res.pagination;
      this.getSelectedListers();
    });
  }

  getSelectedListers(): void {
    this.authHttpService.getUserListers().then(res => {
      this.numberOfSelectedListers = res.length;
      res.data.forEach((selectedLister: any) => {
        const selectedListerIndex = this.listers.findIndex((lister: any) => {
          return selectedLister.lister.id === lister.id;
        })
        if (selectedListerIndex !== -1) {
          this.listers[selectedListerIndex].selected = true;
        }
      })
    })
  }

  onScroll(event: any): void {
    const element = event.target;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 10) {
      if (this.listersPagination?.currentPage && this.listersPagination?.lastPage && this.listersPagination.currentPage < this.listersPagination.lastPage) {
        this.getListers();
      }
    }
  }

  onSelectListerChange(lister: any): void {
    console.log(lister)
    lister.selected ? this.numberOfSelectedListers++ : this.numberOfSelectedListers--;
    this.authHttpService.postToggleUserLister({listerId: lister.id}).then(res => {});
  }

  onClickToContinue(): void {
    const successPageDetails = {
      iconPath: '../assets/icons/outline/icon-check.svg',
      title: 'Your account has been successfully set up',
      btnText: 'Go to homepage',
      redirectToUrl: PAGES_AND_PATHS.home.pagePath
    }
    this.router.navigate([PAGES_AND_PATHS.successAction.pagePath], {state: successPageDetails});
  }
}
