import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationHelperService} from 'src/app/services/helpers/authentication-helper.service';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {EmailRegex} from '../../../constants/general-variables';
import {Router} from '@angular/router';
import {AuthenticationHttpService} from '../../../services/http-services/authentication/authentication-http.service';
import {LoginApiData} from '../../../services/http-services/authentication/autentication-http-interfaces';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  isPasswordVisible = false;
  formTriedToSubmit = false;
  loginForm!: FormGroup;
  isSpinnerActive = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private authenticationHelperService: AuthenticationHelperService,
              private authenticationHttpService: AuthenticationHttpService,
              private router: Router,
              private authHelperService: AuthenticationHelperService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  onSubmitLoginForm(): void {
    this.formTriedToSubmit = true;
    if (this.loginForm.valid) {
      this.isSpinnerActive = true;
      this.authenticationHttpService.login(this.loginForm.value).then((res: LoginApiData) => {
        this.isSpinnerActive = false;
        this.authenticationHelperService.clientLogin(res.user, res.access_token, PAGES_AND_PATHS.home.pagePath);
      }).catch(({error}: {error: { errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        // Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
