import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NewConversationComponent } from '../new-conversation/new-conversation.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { from, Subject, Subscription } from 'rxjs';
import { SetChatUsernamesModule } from 'src/app/pipes/set-chat-usernames/set-chat-usernames.module';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { Location } from '@angular/common';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-chat-list',
  standalone: true,
  imports: [
    NgIf,
    NewConversationComponent,
    NgFor,
    NgClass,
    DatePipe,
    SetChatUsernamesModule,
    RouterModule,
    FormsModule
  ],
  templateUrl: './chat-list.component.html',
})
export class ChatListComponent implements OnInit {
  @Output() isNewChatActive = new EventEmitter<boolean>();
  conversations: Array<any> = [];
  isUserListOpen = false;
  title = 'Chat';
  conversationId: number = 0;
  searchTerm: string = '';
  isLoading: boolean = false;
  isModalOpen: boolean = false;
  pagination!: TlPagination;
  private searchSubject = new Subject<string>();
  pageNum = 1;
  isEventLoaded = false;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  selectedConversationId: number | null = null;
  loggedUser: any;
  newChatSubscription: Subscription | undefined;
  refreshSubscription: Subscription | undefined;
  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private chatHttpService: ChatHttpService,
    private actRoute: ActivatedRoute,
    private chatHelperService: ChatHelperService,
    private location: Location,
    private authenticationHelperService: AuthenticationHelperService
  ) { }

  ngOnInit(): void {
    this.clearSubscription();
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.conversations = [];
    this.getConversations();
    this.listenForRefresh();
    this.listenForNewConversation();
    this.checkForIdChanges();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getConversations(searchTerm)))
    ).subscribe(response => {
      this.conversations = response.data;
      this.pagination = response.pagination;
    });
  }

  checkForIdChanges() {
    this.chatHelperService.selectedConversationId$.subscribe(conversationId => {
      this.selectedConversationId = conversationId;
    });
    this.actRoute.paramMap.subscribe(params => {
      console.log(params.get('id'))
      const conversationId = Number(params.get('id') || '');
      if (conversationId !== 0 && conversationId !== null) {
        this.chatHelperService.selectConversation(conversationId);
      }
    });
  }

  listenForNewConversation() {
    this.chatHelperService.listenToNewConversation(this.loggedUser.id);
    this.newChatSubscription = this.chatHelperService.newChatSubject.subscribe((response: any) => {
      console.log('newChatSubject', response);
      // this.chats.unshift(response.conversation);
      // this.isEventLoaded = true;
      // this.getConversations();
    });
  }

  listenForRefresh() {
    this.chatHelperService.listenForRefreshingList(this.loggedUser.id);
    this.refreshSubscription = this.chatHelperService.refreshListSubject.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      console.log('refreshListSubject', response);
      this.isEventLoaded = true;
      this.getConversations();
    });
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  openConversation(conversationId: number): void {
    this.resetSearch();
    this.location.replaceState(`/chat/${conversationId}`);
    this.chatHelperService.selectConversation(conversationId);
  }

  openUserList() {
    this.isNewChatActive.emit(true);
    this.isUserListOpen = true;
    this.title = 'NewChat';
  }

  back() {
    if (this.isUserListOpen) {
      this.isNewChatActive.emit(false);
      this.isUserListOpen = false;
      this.title = 'Chat';
    } else {
      this.router.navigate([PAGES_AND_PATHS.home.pagePath]);
    }
  }

  getConversations(searchTerm?: string) {
    return from(
      this.chatHttpService.formattedGetConversations(this.pageNum, searchTerm).then((response: {
        data: Array<any>,
        pagination: TlPagination
      }) => {
        console.log('get list of chats', response);
        if (this.isEventLoaded) {
          this.conversations = [];
        }
        this.conversations = [...this.conversations, ...response.data];
        this.pagination = response.pagination;
        this.isEventLoaded = false;
        return response;
      })
    );
  }

  clearSubscription() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = undefined; // Clear the subscription reference
    }
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

}
