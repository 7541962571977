import { Injectable } from '@angular/core';
import { AuthenticationHelperService } from './authentication-helper.service';
import { environment } from 'src/environments/environment';
import Echo from 'laravel-echo';
import { BehaviorSubject, Subject } from 'rxjs';
declare var require: any;
const Pusher = require('pusher-js');

@Injectable({
  providedIn: 'root'
})
export class ChatHelperService {
  newMessageSubject = new Subject();
  newChatSubject = new Subject();
  refreshListSubject = new Subject();
  newEmojiReaction = new Subject();
  deleteEmojiReaction = new Subject();
  private channels: Map<number, any> = new Map();
  private activeListeners: Set<string> = new Set(); // Track active listeners

  private selectedConversationId = new BehaviorSubject<number>(0);
  selectedConversationId$ = this.selectedConversationId.asObservable();

  selectConversation(conversationId: number): void {
    this.selectedConversationId.next(conversationId);
  }

  // Method to handle common Echo instance checks
  private getEchoInstance(): any {
    const echoInstance = (window as any)?.Echo;
    if (!echoInstance) {
      console.error('Echo is not initialized.');
    }
    localStorage.setItem('socketId', echoInstance.socketId());
    return echoInstance;
  }

  // Method to bind common Pusher connection events
  private handleConnectionEvents(echoInstance: any, conversationId?: number) {
    const pusher = echoInstance?.connector?.pusher;
    if (!pusher) {
      console.warn('Pusher is not available for connection event handling.');
      return;
    }

    pusher.connection.bind('connected', () => {
      console.log('Connected to Pusher.');
    });

    pusher.connection.bind('disconnected', () => {
      console.log('Disconnected from Pusher. Attempting to reconnect...');
      if (conversationId) {
        this.tryReconnect(conversationId);
      }
    });

    pusher.connection.bind('reconnecting', (attempt: number) => {
      console.log(`Reconnecting to Pusher... (Attempt: ${attempt})`);
    });

    pusher.connection.bind('failed', () => {
      console.error('Failed to reconnect to Pusher.');
    });
  }

  // Unified reconnection logic
  private tryReconnect(conversationId: number, maxAttempts: number = 5, delay: number = 2000) {
    let attempts = 0;
    const reconnectInterval = setInterval(() => {
      const echoInstance = this.getEchoInstance();
      if (!echoInstance) {
        clearInterval(reconnectInterval);
        return;
      }

      const pusher = echoInstance.connector.pusher;
      if (pusher && pusher.connection.state === 'connected') {
        console.log('Reconnected successfully to Pusher.');
        this.removeListeners(conversationId); // Ensure all listeners are cleaned up
        this.listenToNewMessage(conversationId); // Re-subscribe
        clearInterval(reconnectInterval);
      } else {
        attempts++;
        console.log(`Reconnection attempt ${attempts}`);
        if (attempts >= maxAttempts) {
          console.log('Max reconnection attempts reached. Stopping further attempts.');
          clearInterval(reconnectInterval);
        }
      }
    }, delay);
  }

  // Extract common channel subscription logic
  private subscribeToChannel(channelName: string, event: string, subject: Subject<any>, conversationId?: number) {
    const echoInstance = this.getEchoInstance();
    if (!echoInstance) return;

    const listenerKey = `${channelName}-${event}`;
    if (this.activeListeners.has(listenerKey)) {
      console.log(`Already listening to ${event} on ${channelName}`);
      return;
    }

    const channel = echoInstance.private(channelName);
    channel.listen(event, (e: any) => subject.next(e));
    this.activeListeners.add(listenerKey);

    if (conversationId) {
      this.handleConnectionEvents(echoInstance, conversationId);
    }
  }

  listenToNewMessage(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.ConversationNewMessage', this.newMessageSubject, conversationId);
  }

  listenToNewConversation(userId: number): void {
    const channelName = `App.Models.User.${userId}`;
    this.subscribeToChannel(channelName, '.NewConversation', this.newChatSubject);
  }

  listenForRefreshingList(userId: number): void {
    const channelName = `App.Models.User.${userId}`;
    const echoInstance = this.getEchoInstance();
    if (!echoInstance) return;

    if (!this.channels.has(userId)) {
      console.log('Subscribing to channel:', channelName);
      const channel = echoInstance.private(channelName);
      this.channels.set(userId, channel);

      channel.listen('.RefreshConversations', (e: any) => {
        console.log('REFRESH:', e);
        this.refreshListSubject.next(e);
      });

      this.handleConnectionEvents(echoInstance);
    } else {
      console.log('Already subscribed to channel:', channelName);
    }
  }

  listenToEmojiReaction(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.MessageReaction', this.newEmojiReaction, conversationId);
  }

  listenIfEmojiReactionRemoved(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.MessageReactionDeleted', this.deleteEmojiReaction, conversationId);
  }

  // Cleanup all listeners for a channel
  private removeListeners(conversationId: number): void {
    const echoInstance = this.getEchoInstance();
    if (!echoInstance) return;

    const channelName = `conversations.${conversationId}`;
    const channel = echoInstance.private(channelName);

    if (channel) {
      channel.stopListening('.ConversationNewMessage');
      channel.stopListening('.MessageReaction');
      channel.stopListening('.MessageReactionDeleted');
      console.log('All listeners removed for channel:', channelName);
    }

    this.activeListeners.forEach(listenerKey => {
      if (listenerKey.startsWith(channelName)) {
        this.activeListeners.delete(listenerKey);
      }
    });
  }

  cleanupChannel(userId: number) {
    const channel = this.channels.get(userId);
    if (channel) {
      console.log('Cleaning up channel:', `App.Models.User.${userId}`);
      channel.stopListening('.RefreshConversations');
      this.channels.delete(userId);
    }
  }
}

