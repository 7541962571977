import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyContentComponent } from './my-content.component';



@NgModule({
  declarations: [
    MyContentComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MyContentComponent
  ]
})
export class MyContentModule { }
