import { Component } from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthenticationHttpService} from "../../../../services/http-services/authentication/authentication-http.service";
import {
  FormControlErrorMessagesModule
} from "../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {confirmMatchControlsValidator} from "../../../../validators/form-validators.validator";
import {SpinnerComponent} from "../../../../shared-components/spinner/spinner.component";

@Component({
  selector: 'tl-change-password',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    NgClass,
    SpinnerComponent
  ],
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent {
  formTriedToSubmit = false;
  isCurrentPasswordShow = false;
  isNewPasswordShow = false;
  isConfirmPasswordShow = false;
  isSpinnerActive = false;
  changePasswordForm!: FormGroup;
  isCurrentPasswordErrorShow = false;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toastService: ToastrService,
              private authHttpService: AuthenticationHttpService) { }

  ngOnInit() {
    this.buildForm();
    this.changePasswordForm.controls['current_password'].valueChanges.subscribe((val: string) => {
      this.isCurrentPasswordErrorShow = false;
    });
  }

  buildForm(): void {
    this.changePasswordForm = this.formBuilder.group({
      current_password: ['', [Validators.required, Validators.minLength(8)]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
    }, {
      validators: confirmMatchControlsValidator('new_password', 'password_confirmation')
    });
  }

  onSubmitChangePasswordForm(): void {
    this.formTriedToSubmit = true;
    if (this.changePasswordForm.valid) {
      this.isSpinnerActive = true;
      this.authHttpService.patchUserProfile(this.changePasswordForm.value).then((res: any) => {
        this.isSpinnerActive = false;
        this.toastService.success('Your password has been successfully changed!');
      }).catch((error: any)  => {
        this.isSpinnerActive = false;
        if (error.error.errors.current_password[0] === 'The current password is incorrect.') {
          this.isCurrentPasswordErrorShow = true;
        }
      });
    }
  }
}
