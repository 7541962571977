import { Component } from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {AuthenticationHttpService} from "../../../../services/http-services/authentication/authentication-http.service";
import {ToastrService} from "ngx-toastr";
import {
  FormControlErrorMessagesModule
} from "../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {SpinnerComponent} from "../../../../shared-components/spinner/spinner.component";

@Component({
  selector: 'tl-change-email',
  standalone: true,
  imports: [
    NgIf,
    FormControlErrorMessagesModule,
    NgClass,
    ReactiveFormsModule,
    SpinnerComponent
  ],
  templateUrl: './change-email.component.html',
})
export class ChangeEmailComponent {
  changeEmailForm!: FormGroup;
  isSpinnerActive = false;
  formTriedToSubmit = false;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private authHttpService: AuthenticationHttpService,
              public authHelperService: AuthenticationHelperService) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.changeEmailForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });
  }

  onSubmitChangeEmailForm(): void {
    this.formTriedToSubmit = true;
    if (this.changeEmailForm.valid) {
      this.isSpinnerActive = true;
      this.authHttpService.patchUserProfile(this.changeEmailForm.value).then(res => {
        this.authHelperService.setUserData({...this.authHelperService.getUserData, username: res.username})
        this.isSpinnerActive = false;
        this.toastService.success('Your email address has been successfully changed!');
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
