import {Component, OnInit} from '@angular/core';
import {TlPagination} from "../../../interfaces/general-interfaces";
import {AuthenticationHttpService} from "../../../services/http-services/authentication/authentication-http.service";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";

@Component({
  selector: 'tl-select-topics',
  templateUrl: './select-topics.component.html',
})
export class SelectTopicsComponent implements OnInit {
  topics: any[] = [];
  numberOfSelectedTopics = 0;
  topicsPagination: TlPagination | undefined;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private authHttpService: AuthenticationHttpService) { }

  ngOnInit(): void {
    this.getTopics();
  }

  getTopics(): void {
    const page = this.topicsPagination?.currentPage ? this.topicsPagination.currentPage + 1 : 1;
    this.authHttpService.getTopics({page}).then(res => {
      this.topics = [...this.topics, ...res.data];
      this.topicsPagination = res.pagination;
      this.getSelectedTopics();
    });
  }

  getSelectedTopics(): void {
    this.authHttpService.getUserTopics().then(res => {
      this.numberOfSelectedTopics = res.length;
      res.forEach((selectedTopic: any) => {
        const selectedTopicIndex = this.topics.findIndex((topic: any) => {
          return selectedTopic.topic.id === topic.id;
        })
        if (selectedTopicIndex !== -1) {
          this.topics[selectedTopicIndex].selected = true;
        }
      })
    })
  }

  onScroll(event: any): void {
    const element = event.target;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 10) {
      if (this.topicsPagination?.currentPage && this.topicsPagination?.lastPage && this.topicsPagination.currentPage < this.topicsPagination.lastPage) {
        this.getTopics();
      }
    }
  }

  onSelectTopicChange(topic: any): void {
    topic.selected ? this.numberOfSelectedTopics++ : this.numberOfSelectedTopics--;
    this.authHttpService.postToggleUserTopic({topicId: topic.id}).then(res => {});
  }
}
