export const EnLang = {name: 'en', value: 'English', jsonPath: 'LANGUAGES.en', flag: '../../../../assets/icons/icon-flags/icon-english.svg'};
export const EsLang = {name: 'es', value: 'Spanish', jsonPath: 'LANGUAGES.es', flag: '../../../../assets/icons/icon-flags/icon-spanish.svg'};
export const RuLang = {name: 'ru', value: 'Russian', jsonPath: 'LANGUAGES.ru', flag: '../../../../assets/icons/icon-flags/icon-russian.svg'};
export const PtLang = {name: 'pt', value: 'Portuguese', jsonPath: 'LANGUAGES.pt', flag: '../../../../assets/icons/icon-flags/icon-portuguese.svg'};
export const FrLang = {name: 'fr', value: 'French', jsonPath: 'LANGUAGES.fr', flag: '../../../../assets/icons/icon-flags/icon-french.svg'};
export const DeLang = {name: 'de', value: 'German', jsonPath: 'LANGUAGES.de', flag: '../../../../assets/icons/icon-flags/icon-german.svg'};
export const TrLang = {name: 'tr', value: 'Turkish', jsonPath: 'LANGUAGES.tr', flag: '../../../../assets/icons/icon-flags/icon-turkish.svg'};
export const ItLang = {name: 'it', value: 'Italian', jsonPath: 'LANGUAGES.it', flag: '../../../../assets/icons/icon-flags/icon-italian.svg'};
export const NlLang = {name: 'nl', value: 'Dutch', jsonPath: 'LANGUAGES.nl', flag: '../../../../assets/icons/icon-flags/icon-dutch.svg'};
export const ElLang = {name: 'el', value: 'Greek', jsonPath: 'LANGUAGES.el', flag: '../../../../assets/icons/icon-flags/icon-greek.svg'};
export const SvLang = {name: 'sv', value: 'Swedish', jsonPath: 'LANGUAGES.sv', flag: '../../../../assets/icons/icon-flags/icon-swedish.svg'};
export const NoLang = {name: 'no', value: 'Norwegian', jsonPath: 'LANGUAGES.no', flag: '../../../../assets/icons/icon-flags/icon-norwegian.svg'};
export const DaLang = {name: 'da', value: 'Danish', jsonPath: 'LANGUAGES.da', flag: '../../../../assets/icons/icon-flags/icon-danish.svg'};
export const BgLang = {name: 'bg', value: 'Bulgarian', jsonPath: 'LANGUAGES.bg', flag: '../../../../assets/icons/icon-flags/icon-bulgarian.svg'};
export const DefaultLang = EnLang;
export const AvailableLanguages = [EnLang, EsLang, RuLang, PtLang, FrLang, DeLang, TrLang, ItLang, NlLang, ElLang, SvLang, NoLang, DaLang, BgLang];
