export enum LocalStorageProperties {
  token = 'tl-token',
  user = 'tl-user',
}

export enum UserRoles {
  user = 'user',
  expert = 'expert'
}

export enum AccountTypes {
  individual = 'individual',
  company = 'company'
}
